import React from "react";
import NavBar from "../components/navigation/NavBar";
import MobileNav from "../components/navigation/MobileNav";
import ImageComp from "../components/Image";
import GradientText from "../components/GradientText";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

const WhyChooseUs = () => {
  const visions = [
    { image: "/images/diverse.png", text: "Diverse Service offerings" },
    { image: "/images/expert.png", text: "Expert Team" },
    { image: "/images/extensive.png", text: "Extensive Network" },
    { image: "/images/proven.png", text: "Proven Results" },
  ];

  const benifits = [
    {
      image: "/images/maximize.svg",
      title: "Maximize Cash Flow",
      details: "Keep cash flow healthy while investing in innovation.",
    },
    {
      image: "/images/drive.svg",
      title: "Drive Sustainable Growth",
      details: "Align costs with business growth for scalable success.",
    },
    {
      image: "/images/partner.svg",
      title: "Partner with Experts",
      details:
        "Leverage our expertise to accelerate your product development and market success.",
    },
    {
      image: "/images/risk.svg",
      title: "Risk-Free Innovation",
      details: "If the project fails, you're not burdened with repayment",
    },
    {
      image: "/images/reduce.svg",
      title: "Reduce Financial Pressure",
      details: "Spread costs over time, freeing capital for other needs.",
    },
  ];

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Why Choose Us | Sky Venture Studios</title>
        <link rel="canonical" href="https://www.skyventure.xyz/" />
      </Helmet>
      <section className="w-full bg-why-choose-us-bg bg-cover bg-no-repeat h-[797px] md:h-screen 2xl:h-[1013px] relative mb-20 md:mb-[100px]">
        {/* Navbar */}
        <div className="hidden md:absolute lg:block top-[2rem] left-0 right-0">
          <NavBar />
        </div>
        <div className="fixed z-50 lg:hidden top-[0rem] left-0 right-0 bg-white py-4">
          <MobileNav />
        </div>

        {/* Main Content */}
        <div className="flex flex-col justify-end items-center h-full text-white pb-10">
          <ImageComp image="/images/innovate-text.svg" />
          <p className="font-extrabold uppercase text-2xl md:text-7xl 2xl:text-app-100 mb-[30px] 2xl:mb-[96px] max-w-6xl 2xl:max-w-[1640px] text-center">
            No Failure Commitment, No Collateral, No Risk,{" "}
            <span className="text-[#EE3248]">Stress-Free</span> Financing
          </p>
          <p className="font-medium text-sm md:text-2xl 2xl:text-app-32 text-center max-w-4xl mx-auto px-4">
            Choose Sky Venture Studios for minimal upfront costs and flexible,
            stress-free payment options.
          </p>
        </div>
      </section>
      {/* section2 */}
      <div className="bg-cover bg-no-repeat bg-about-section-2-bg h-full 2xl:h-[946px] px-4 md:px-0 mb-20 md:mb-[100px] lg:gap-[50px] 2xl:gap-[141px]">
        <div className="w-full md:w-4/5 mx-auto grid grid-cols-1 md:grid-cols-2">
          <div className="flex flex-col gap-2 md:gap-11 order-2 md:order-1">
            <GradientText text={"PROBLEM GLOBALLY"} />
            <div>
              <p className="text-[#EE3248] uppercase text-2xl 2xl:text-app-40 font-semibold pb-2 md:pb-8 2xl:pb-[50px]">
                The Cost of Innovation
              </p>
              <div className="flex flex-col gap-6">
                <p className="text-base md:text-3xl lg:text-5xl 2xl:text-app-64 font-semibold">
                  High Initial Costs, Stifled Growth
                </p>
                <p className="font-medium text-sm md:text-xl 2xl:text-app-3xl-2">
                  Startups face financial hurdles that slow down innovation and
                  market entry
                </p>
              </div>
            </div>
          </div>
          <ImageComp
            image="/images/why-1.png"
            styles="order-1 md:order-2 object-cover"
          />
        </div>
      </div>
      {/* section3 */}
      <div className="bg-cover bg-no-repeat bg-about-section-2-bg h-full 2xl:h-[946px] px-4 md:px-0 mb-20 md:mb-[100px]">
        <div className="w-full md:w-4/5 mx-auto grid grid-cols-1 md:grid-cols-2 lg:gap-[50px] 2xl:gap-[141px]">
          <ImageComp image="/images/why-2.png" styles="object-cover" />
          <div className="flex flex-col gap-2 md:gap-11">
            <GradientText text={"Our Solution"} />
            <div>
              <p className="text-[#EE3248] uppercase text-2xl 2xl:text-app-40 font-semibold pb-2 md:pb-8 2xl:pb-[50px]">
                Your Path to Innovation
              </p>
              <div className="flex flex-col gap-6">
                <p className="text-base md:text-3xl lg:text-5xl 2xl:text-app-64 font-semibold">
                  Tailored Financing for Your Growth
                </p>
                <p className="font-medium text-sm md:text-xl 2xl:text-app-3xl-2">
                  Our flexible payment plans remove financial barriers, enabling
                  seamless innovation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* section4 */}
      <div className="h-full 2xl:h-[946px] px-4 md:px-0 mb-20 md:mb-[100px]">
        <div className="md:w-4/5 mx-auto">
          <p className="text-left md:text-center font-bold text-2xl md:text-5xl lg:text-7xl 2xl:text-app-100 text-app-purple uppercase mb-[50px] 2xl:mb-[100px]">
            Our Core Values
          </p>
          <div className="w-full grid grid-cols-1 md:grid-cols-2 items-center lg:gap-[50px] 2xl:gap-[141px]">
            <div className="flex flex-col gap-6 2xl:gap-[85px] order-2 md:order-1">
              {benifits.map((item, index) => (
                <div
                  className="flex items-start gap-8 2xl:gap-[123px]"
                  key={index}
                >
                  <ImageComp
                    image={item?.image}
                    styles="w-10 h-10 md:w-63 md:h-63"
                  />
                  <div className="flex flex-col gap-1 2xl:gap-3.5">
                    <p className="text-base md:text-2xl 2xl:text-app-40 font-semibold">
                      {item?.title}
                    </p>
                    <p className="text-sm 2xl:text-xl">{item?.details}</p>
                  </div>
                </div>
              ))}
            </div>
            <ImageComp
              image="/images/calculate.png"
              styles="order-1 md:order-2"
            />
          </div>
        </div>
      </div>
      {/* section5 */}
      <div className="bg-cover bg-no-repeat bg-finance-bg h-full 2xl:h-[946px] px-4 md:px-0 mb-20 md:mb-[100px] lg:gap-[50px] 2xl:gap-[141px] py-[185px]">
        <div className="w-full md:w-4/5 mx-auto">
          <div className="flex flex-col items-center justify-center">
            <p className="text-center text-white uppercase font-bold text-2xl md:text-5xl lg:text-7xl 2xl:text-app-100">
              Financing Option
            </p>
            <ImageComp image="/images/finance-text.svg" />
            <p className="text-center  text-2xl md:text-3xl lg:text-5xl 2xl:text-app-64 mb-[22px] font-medium text-white">
              Small Down Payment, 60-Month Repayment
            </p>
            <p className="text-center text-sm md:text-xl 2xl:text-app-3xl-2 font-medium text-white">
              Start with a 25% down, repay the rest for over 60 months with no
              stress
            </p>
          </div>
        </div>
      </div>
      <Contact />
      <Footer />
    </>
  );
};

export default WhyChooseUs;
