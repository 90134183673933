import React from "react";
import NavBar from "../components/navigation/NavBar";
import ImageComp from "../components/Image";
import CircledText from "../components/CircledText";
import GradientText from "../components/GradientText";
import Accordion from "../components/Accordion";
import BlogCardComponent from "../components/BlogCard";
import Footer from "../components/Footer";
import Contact from "../components/Contact";
import ServiceCardComponent from "../components/ServiceCard";
import MobileNav from "../components/navigation/MobileNav";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import AnimatedText from "../components/AnimatedText";

const Card = ({ count, title, bg1, bg2, path, list }) => {
  return (
    <Link to={path} className="group">
      <div
        className={`relative z-30  w-[320px] h-[330px] ${bg1}  rounded-3xl overflow-hidden bg-cover bg-center transition-all ease-in-out duration-100`}
      >
        <div className="rounded-full bg-radial-gradient absolute -right-[70%] -bottom-[70%] h-0 w-0 flex group-hover:h-[540px] group-hover:w-[540px]  transition-all duration-500 ease-out"></div>
        <div className="absolute bottom-4 left-4 max-w-[200px] group-hover:-translate-y-32 transition-transform duration-500 ease-in-out">
          <div className="mb-.5 text-3xl font-extrabold text-white transition-all duration-500 ease-in-out group-hover:text-shadow-outline md:text-app-50 font-roboto ">
            {count}
          </div>
          <div className="bg-white w-9 md:w-[56px] h-1 mb-1 transition-all duration-500 ease-in-out group-hover:bg-app-purple"></div>
          <div className="text-sm font-extrabold text-white uppercase transition-all duration-500 ease-in-out group-hover:text-shadow-outline md:text-app-22 font-roboto">
            {title}
          </div>
        </div>
        <ul className="absolute transition-all ease-in-out delay-150 opacity-0 duration-400 left-6 top-48 group-hover:opacity-100">
          {list?.map((item, i) => (
            <li className="text-xs font-medium text-white list-disc" key={i}>
              {item}
            </li>
          ))}
        </ul>
        <div className="absolute bottom-[-1.5rem] right-[-1.5rem] cursor-pointer group">
          <div className="relative w-[116px] h-[116px] bg-app-purple rounded-full flex items-center justify-center group-hover:bg-white group-hover:h-[130px] group-hover:w-[130px] transition-all ease-in-out duration-500">
            {/* <div className="text-4xl text-white">&#8594;</div> */}
            <ImageComp
              image="/images/offer-arrow-white.svg"
              styles="block group-hover:hidden transition-all ease-in-out duration-100"
            />
            <ImageComp
              image="/images/offer-arrow-purple.svg"
              styles="hidden group-hover:block transition-all ease-in-out duration-600"
            />
          </div>
        </div>
      </div>
    </Link>
  );
};

const Home = () => {
  const offers = [
    {
      count: "01",
      title: "ARTIFICIAL INTELLIGENCE",
      bg1: "bg-bg-offer-1",
      bg2: "group-hover:bg-bg-offer-1-variant",
      path: "/services/artificial-intelligence",
    },
    {
      count: "02",
      title: "WEB 3.0 DEVELOPMENT",
      bg1: "bg-bg-offer-2",
      bg2: "group-hover:bg-bg-offer-2-variant",
      path: "/services/web3-development",
      list: [
        "UX/UI design",
        "Prototype/MVP development",
        "DApp Creation",
        "Smart Contract Development",
        "Tokenomics",
        "Web3 Integration Strategy",
      ],
    },
    {
      count: "03",
      title: "CREATIVE AND MULTIMEDIA DESIGN",
      bg1: "bg-bg-offer-4",
      bg2: "group-hover:bg-bg-offer-4-variant",
      path: "/services/creative-design",
      list: [
        "2D and 3D design",
        "Animation",
        "Cinematic Trailers",
        "Mascot Design",
      ],
    },
    {
      count: "04",
      title: "GAME DEVELOPMENT",
      bg1: "bg-bg-offer-6",
      bg2: "group-hover:bg-bg-offer-6-variant",
      path: "/services/game-development",
      list: ["TON Game", "Unreal Engine 5 ", "Unity", "Game level design"],
    },
    {
      count: "05",
      title: "SOFTWARE DEVELOPMENT",
      bg1: "bg-bg-offer-3",
      bg2: "group-hover:bg-bg-offer-3-variant",
      path: "/services/software-development",
      list: [
        "Pitch Deck Design",
        "Whitepaper Creation",
        "Web3 Identity Design",
        "Brand Identity Design",
      ],
    },
  ];

  return (
    <div className="overflow-x-hidden">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Home | Sky Venture Studios</title>
        <link rel="canonical" href="https://www.skyventure.xyz/" />
      </Helmet>
      <section className="flex flex-end md:flex-center py-8 w-full bg-hero-bg bg-cover bg-center bg-no-repeat h-[100vh] md:h-[850px] relative pt-[4rem]">
        {/* Adjusted padding-top */}

        {/* Navbar */}
        <div className="hidden md:absolute lg:block top-[2rem] left-0 right-0">
          <NavBar />
        </div>
        <div className="fixed z-50 lg:hidden top-[0rem] left-0 right-0 bg-white py-4">
          <MobileNav />
        </div>

        {/* Main Content */}
        <div className="flex flex-col items-center justify-end mt-[12.5rem]">
          {/* Adjusted margin-top */}
          <ImageComp
            image="/images/risk free.svg"
            className=" h-[150px] md:block hidden md:mb-6"
          />
          <ImageComp
            image="/images/risk-free-m.svg"
            className=" h-[150px] block md:hidden md:mb-6"
          />
          <div>
            <p className="mb-6 text-3xl font-bold text-white text-center uppercase lg:text-5xl 2xl:text-app-100">
              Empower Your Vision with Our Risk-Sharing Solutions
            </p>
          </div>
          <p className="text-center font-medium text-xs lg:text-xl text-white 2xl:text-app-3xl w-full 2xl:max-w-[1268px] max-w-[800px] flex justify-center md:mb-12 2xl:mb-[100px]">
            Begin with a small initial payment and enjoy flexible 60-month
            repayments without collateral. If your project falters, we cover the
            risk.
          </p>
        </div>
      </section>
      <div>
        {/* section2 */}
        <div>
          <ImageComp image="/images/SKY VENTURE.png" />
          <div className="flex relative  w-full">
            <div className="flex flex-col justify-center mt-12 items-center w-full">
              {/* <ImageComp image="/images/get-to-know.svg" /> */}
              <h2 className="font-bold text-2xl md:text-6xl 2xl:text-app-100 uppercase text-[#6F00FF]">
                GET TO KNOW US
              </h2>
              <div className="pt-11 flex flex-col items-center">
                <p className="text-sm font-medium text-center md:w-2/3 lg:text-2xl 2xl:text-app-3xl-2 pb-11">
                  At Sky Venture Studios, we specialize in seamless software
                  development across AI, blockchain, gaming and more. Launch
                  your vision with minimal initial investment and flexible
                  payment options, empowering you to innovate freely and sustain
                  growth.
                </p>
                <Link to="/about-us">
                  <button className="bg-[#6F00FF] py-4 2xl:py-[34px] px-16 2xl:px-[115px] rounded-[5px] text-white">
                    Find Out More
                  </button>
                </Link>
              </div>
            </div>
            <div className="absolute -bottom-24 right-6">
              <ImageComp
                image="/images/studio-text.svg"
                styles="hidden md:block h-[150px]"
              />
            </div>
          </div>
        </div>
        <div className="pt-16 2xl:pt-[173px] flex justify-center">
          <div className="">
            <div className="flex flex-col md:gap-48 w-full md:py-24  px-4 pb-20 mx-auto md:px-0 md:w-[85%]">
              <div className="grid grid-cols-1 gap-12 md:gap-16 md:grid-cols-2 mb-16 2xl:mb-[160px]">
                <GradientText text={"PROBLEM GLOBALLY"} />
                <div className="md:mt-24">
                  <div className="md:pl-4">
                    <CircledText text={"The Innovation Dilemma"} />
                  </div>
                  <div className="flex flex-col gap-6 mt-4 ">
                    <p className="text-base font-semibold md:text-4xl 2xl:text-app-64">
                      High Cost, Slow Target
                    </p>
                    <p className="text-sm font-medium md:text-lg 2xl:text-app-3xl-2">
                      Startups frequently encounter steep development costs and
                      slow timelines, preventing them from bringing innovative
                      ideas to market quickly.
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col-reverse gap-12 md:flex-row md:gap-16">
                <div className="gap-12 pt-0 ">
                  <CircledText text={"Innovate Now, Pay Later"} />
                  <div className="flex flex-col gap-8 mt-4 md:mt-8">
                    <p className="text-base font-semibold md:text-sm">
                      Simplified Development, Accelerated Success
                    </p>
                    <p className="text-sm font-medium md:text-lg 2xl:text-app-3xl-2">
                      We streamline your development process with flexible
                      payment plans, allowing you to innovate without financial
                      strain, bringing your vision to market faster.
                    </p>
                  </div>
                </div>
                <GradientText text={"SKY VENTURE STUDIOS SOLUTION"} />
              </div>
            </div>
            <div className="px-4 md:px-0 w-full md:w-4/5 2xl:md:w-[90%] mx-auto md:pt-[200px]">
              <div className="flex flex-col md:flex-row items-center mb-16 2xl:mb-[160px] gap-[60px]">
                <div className="flex md:w-3/5">
                  <ImageComp
                    image="/images/hero-section-2.png"
                    styles="max-w-[500px] md:h-[90vh] 2xl:w-full hidden md:block"
                  />
                  <ImageComp
                    image="/images/hero-section-2-m.png"
                    styles="block md:hidden"
                  />
                </div>
                <div className="flex flex-col gap-4 md:w-full">
                  <p className="text-[#EE3248] md:pl-6 font-semibold text-2xl 2xl:text-app-40 uppercase">
                    Risk-Free Partnership
                  </p>
                  <h1 className="font-bold text-2xl md:text-6xl 2xl:text-app-100 uppercase text-[#6F00FF]">
                    No Pay for Failed Projects
                  </h1>
                  <p className="text-base font-medium md:text-2xl 2xl:text-app-32">
                    If your project fails, you owe nothing. We share the risk to
                    ensure your peace of mind
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full md:w-4/5 2xl:md:w-[90%] mx-auto px-4 md:px-0 pb-[35px] 2xl:[106px]">
              <ImageComp image="images/offers.svg" />
              <div className="relative grid justify-center gap-8 mx-auto w-fit grid-cols-0 sm:grid-cols-2 lg:grid-cols-3 ">
                {offers?.map((item, index) => (
                  <Card
                    key={index}
                    title={item?.title}
                    count={item?.count}
                    bg1={item?.bg1}
                    bg2={item?.bg2}
                    path={item?.path}
                    list={item?.list}
                  />
                ))}
              </div>
            </div>
            <div className="px-4 flex justify-center relative mx-auto md:px-0 w-full md:w-4/5 2xl:md:w-[90%] mt-[100px] pb-[100px]">
              <div className="absolute top-0 left-[50%] -translate-x-[50%] w-[110%]">
                <ImageComp image="images/portfolio-text.svg" />
              </div>
              {/* <div className="grid grid-cols-1 md:grid-cols-3 mb-16 w-full items-center 2xl:mb-[160px] gap-[62px]">
                <div className="flex items-center justify-center w-full">
                  <h1 className="font-bold text-2xl md:text-6xl text-center w-full md:text-left 2xl:text-app-100 uppercase text-[#6F00FF]">
                    NOTABLE PORTFOLIO
                  </h1>
                </div>
                <ImageComp
                  image="images/hero-section-3.png"
                  styles="col-span-2"
                />
              </div> */}
              <div className="grid grid-cols-1 lg:grid-cols-2 mb-16 2xl:mb-[160px] gap-[62px] 2xl:gap-x-24">
                <div className="flex items-center justify-center lg:justify-start">
                  <h1 className="font-bold text-2xl text-center md:text-6xl 2xl:text-app-100 uppercase text-[#6F00FF]">
                    NOTABLE PORTFOLIO
                  </h1>
                </div>
                <ImageComp
                  image="images/hero-section-3.png"
                  styles="col-span-2 xl:col-span-1"
                />
              </div>
            </div>
            <div className="md:py-[100px] py-6 bg-black">
              <div className="flex justify-center">
                <div>
                  <div className="mx-auto">
                    <div className="relative w-full h-32 flex items-center justify-center mb-3.5">
                      {/* <div className="absolute inset-0 flex items-center justify-center">
                        <span className="text-3xl font-bold tracking-wider text-gray-400 uppercase md:text-7xl 2xl:text-app-126 opacity-20">
                          Ecosystem Partners
                        </span>
                      </div> */}
                      {/* <div className="relative">
                        <AnimatedText
                          node={
                            <span className="relative z-10 text-2xl uppercase md:text-6xl 2xl:text-app-100">
                              Our Partners
                            </span>
                          }
                        />
                      </div> */}
                      <div className="absolute inset-0 flex flex-col items-center justify-center gap-y-3 ">
                        <div className="space-x-2 top-left-animated ">
                          <span className="inline-block w-[16.06px] h-[11.24px] bg-[#6F00FF] rounded-md"></span>
                          <span className="inline-block w-[35.32px] h-[11.24px] bg-[#6F00FF] rounded-md"></span>
                          <span className="inline-block w-[107.06px] h-[11.24px] bg-[#6F00FF] rounded-md"></span>
                        </div>
                        <span className="text-2xl md:text-3xl xl:text-7xl  text-white font-bold uppercase tracking-wider">
                          Our Partners
                        </span>
                        <div className="space-x-2 top-right-animated ">
                          <span className="inline-block w-[107.06px] h-[11.24px] bg-[#6F00FF] rounded-md"></span>
                          <span className="inline-block w-[35.32px] h-[11.24px] bg-[#6F00FF] rounded-md"></span>

                          <span className="inline-block w-[16.06px] h-[11.24px] bg-[#6F00FF] rounded-md"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="relative w-4/5 mx-auto">
                    <div className="absolute bottom-0 flex w-full h-full -translate-x-[40%] left-1/2">
                      <ImageComp
                        className="bg-cover"
                        image="/images/partners-bg.png"
                      />
                    </div>
                    <ImageComp image="/images/logos.png" />
                  </div>
                </div>
              </div>
            </div>
            {/* FAQ Section */}
            <div className="w-full md:w-4/5 px-4 md:px-0 mx-auto pb-[100px]">
              <ImageComp image="/images/faq-text.svg" />
              <div className="grid grid-cols-1 md:grid-cols-2 items-center mb-16 2xl:mb-[160px] gap-[62px]">
                <div>
                  <div className="flex flex-col items-center gap-2 mb-8 md:mb-20">
                    <p className="font-medium text-app-sm text-app-purple">
                      A PROFESSIONAL SOFTWARE DEVELOPMENT AGENCY.
                    </p>
                    <p className="text-3xl font-medium text-center 2xl:text-5xl">
                      Full-featured. Not complicated.
                    </p>
                  </div>
                  <ImageComp
                    image="/images/hero-section-4.png"
                    // styles="hiddem md:block"
                  />
                  {/* <ImageComp
                    image="/images/hero-section-4-m.png"
                    styles="block md:hidden"
                  /> */}
                </div>
                <div className="flex flex-col gap-6">
                  <Accordion
                    text={"What sets Sky Venture Studios apart?"}
                    detials={
                      "Our unique model reduces upfront costs, enabling visionaries to start projects immediately with minimal financial strain, supported by our extensive tech expertise."
                    }
                  />
                  {/* <ServiceCardComponent /> */}
                  <Accordion
                    text={"What does Sky Venture Studios specialize in?"}
                    detials='Sky Venture Studios specializes in innovative software development solutions across AI, blockchain, and gaming, offering flexible "Innovate Now, Pay Later" options.'
                  />
                  <Accordion
                    text={"How does the 'Innovate Now, Pay Later' model work?"}
                    detials="Our financing model allows entrepreneurs to begin development with a small initial payment, spreading the remaining cost over time to ease cash flow and accelerate growth."
                  />
                  <Accordion
                    text={"Can Sky Venture Studios help with project funding?"}
                    detials="Yes, besides technological development, we assist in connecting founders with funding opportunities and strategic partners to enhance project viability and reach."
                  />
                  <Accordion
                    text={
                      "What types of projects can Sky Venture Studios handle?"
                    }
                    detials="We handle a diverse range of projects from web and mobile applications to complex blockchain and AI-driven platforms, tailoring solutions to meet specific visionary needs."
                  />
                </div>
              </div>
            </div>
            {/* Blog Section */}
            {/*             <div className="pb-[100px]">
              <div className="flex justify-center w-full">
                <ImageComp image="/images/blog-text.svg" />
              </div>

              <div className="w-4/5 mx-auto">
                <div className="flex flex-wrap items-center justify-center gap-11">
                  <BlogCardComponent
                    image="/images/blog-1.png"
                    date="30"
                    month="JAN"
                    title="mastering the machine propel your business."
                    details="Discover the impact of AI Competition in business. Learn how AI strategies give you a competitive edge in simple terms. In today’s..."
                  />
                  <BlogCardComponent
                    image="/images/blog-2.png"
                    date="50"
                    month="JAN"
                    title="Beyond Boundaries: AI Revolutionizing Human."
                    details="Discover the Artificial Intelligence Examples in Daily Life, from voice-activated assistants to personalized search engine experiences..."
                  />
                  <BlogCardComponent
                    image="/images/blog-3.png"
                    date="22"
                    month="SEP"
                    title="Octaloop Technologies: Unlocking The Power."
                    details="Redefining Success Through Technology Innovation! In today’s swiftly evolving digital landscape, businesses are on a constant..."
                  />
                </div>
              </div>
            </div> */}
            <Contact />
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
