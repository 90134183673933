import React from 'react'
import ImageComp from './Image'

const AnimatedText = ({ node, yPosition }) => {
    return (
        <div className="relative text-4xl font-bold text-white">
            <div className={`absolute flex animate-bounce-x -top-${yPosition || '6'} left-20`}>
                <ImageComp className="hidden md:block" image="/images/left.svg" />
                <ImageComp className="block md:hidden" image="/images/left-mobile.svg" />
            </div>
            <div className={`absolute flex animate-bounce-x-reverse -bottom-${yPosition || '6'} right-20`}>
                <ImageComp className="hidden md:block" image="/images/right.svg" />
                <ImageComp className="block md:hidden" image="/images/right-mobile.svg" />
            </div>
            <div className="block">
                {node}
            </div>
        </div>
    )
}

export default AnimatedText