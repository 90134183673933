import React from "react";
import NavBar from "../../components/navigation/NavBar";
import MobileNav from "../../components/navigation/MobileNav";
import ImageComp from "../../components/Image";
import ServiceCard1 from "../../components/ServiceCard1";
import ServiceCard2 from "../../components/ServiceCard2";
import Contact from "../../components/Contact";
import Footer from "../../components/Footer";
import Marquee from "../../components/Marque";
import { Helmet } from "react-helmet";

const GameDevelopment = () => {
  const texts = [
    "Artificial Intelligence",
    "web 3.0",
    "SOFTWARE DEVELOPMENT",
    "CREATIVE AND MULTIMEDIA DESIGNS",
    "STRATEGIS ADVISORY",
    "FUND RAISING STRATEGY",
    "GAMES DEVELOPMENT",
    "LEAD AMBASSADOR",
  ];

  const services = [
    {
      image: "/images/game-1-m.png",
      titile: "UNITY",
      details:
        "Utilize versatile game development with Unity for cross-platform play",
      isBackground: false,
      count: "01",
    },
    {
      image: "/images/game-2-m.png",
      titile: "Unreal Engine",
      details:
        "Develop stunning games with advanced visual effects and complex simulations.",
      isBackground: true,
      count: "02",
    },
    {
      image: "/images/game-3-m.png",
      titile: "Ton Chain Game",
      details:
        "Integrate blockchain technology into games for secure and decentralized gameplay",
      isBackground: false,
      count: "03",
    },
  ];
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Game Development | Sky Venture Studios</title>
        <link rel="canonical" href="https://www.skyventure.xyz/" />
      </Helmet>
      <section className="w-full bg-ai-bg bg-cover bg-no-repeat h-[600px] 2xl:h-[761px] relative">
        {/* Navbar */}
        <div className="hidden md:absolute lg:block top-[2rem] left-0 right-0">
          <NavBar />
        </div>
        <div className="fixed z-50 lg:hidden top-[0rem] left-0 right-0 bg-white py-4">
          <MobileNav />
        </div>

        {/* Main Content */}
        <div className="flex flex-col items-center justify-end h-full pb-10 text-white">
          <p className="font-extrabold uppercase text-center text-2xl md:text-7xl 2xl:text-app-100 mb-[30px]">
            Game <br></br>Development
          </p>
          <p className="max-w-4xl px-4 mx-auto text-sm font-normal text-center md:text-2xl 2xl:text-app-32">
            Create immersive gaming experiences using industry-leading platforms
            and innovative technologies.
          </p>
        </div>
      </section>
      <Marquee texts={texts} />
      {/* section2 mobile */}
      <div className="pt-[148px] w-full md:w-4/5 px-4 md:px-0 mx-auto hidden md:block">
        <div className="grid grid-cols-1 gap-2 lg:grid-cols-2 mb-[100px]">
          <ServiceCard1
            title="unity"
            image="bg-bg-game-1"
            details="Utilize versatile game development with Unity for cross-platform play"
            number="01"
          />
          <ServiceCard2
            title="Unreal Engine"
            image="bg-bg-game-2"
            details="Develop stunning games with advanced visual effects and complex simulations."
            number="02"
          />
        </div>
        <div className="grid grid-cols-1 gap-2 lg:grid-cols-2 mb-[100px]">
          <ServiceCard2
            title="Ton Chain Game"
            image="bg-bg-game-3"
            details="Integrate blockchain technology into games for secure and decentralized gameplay"
            number="03"
          />
        </div>
      </div>
      {/* section2 mobile */}
      <div className="block w-full px-4 mx-auto md:w-4/5 md:px-0 md:hidden">
        {services.map((item, index) => (
          <div
            className={`flex flex-col mb-6 ${
              item?.isBackground
                ? "text-white bg-[#40259E]"
                : "text-black bg-white"
            }`}
            key={index}
          >
            <ImageComp image={item?.image} />
            <div className="flex flex-row gap-6 pt-[27px] pb-[65px]">
              <p className="font-bold text-app-64">{item?.count}</p>
              <div className="flex flex-col">
                <p className="text-sm font-semibold uppercase">
                  {item?.titile}
                </p>
                <p className="text-sm">{item?.details}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Contact />
      <Footer />
    </>
  );
};

export default GameDevelopment;
