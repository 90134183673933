import React from "react";

const GradientText = ({ text }) => {
  return (
    <div className="font-bold">
      <h1 className="text-2xl font-bold text-transparent uppercase bg-text-gradient md:text-5xl lg:text-7xl 2xl:text-app-100 bg-clip-text">
        {text}
      </h1>
    </div>
  );
};

export default GradientText;
