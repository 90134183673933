import React from "react";
import NavBar from "../../components/navigation/NavBar";
import MobileNav from "../../components/navigation/MobileNav";
import ImageComp from "../../components/Image";
import ServiceCard2 from "../../components/ServiceCard2";
import ServiceCard1 from "../../components/ServiceCard1";
import Contact from "../../components/Contact";
import Footer from "../../components/Footer";
import Marquee from "../../components/Marque";
import { Helmet } from "react-helmet";
import AnimatedText from "../../components/AnimatedText";

const ProcessCard = ({ title, count, details, bg }) => {
  return (
    <div
      className={`${bg} px-8 py-11 flex flex-col gap-2.5 w-full md:max-w-[350px] 2xl:max-w-[480px] h-[312px] lg:h-[280px] 2xl:h-[312px] overflow-hidden`}
    >
      <p className="text-5xl font-bold md:text-6xl 2xl:text-app-100-24 2xl:font-medium">
        {count}
      </p>
      <p className="text-sm font-medium 2xl:text-xl">{title}</p>
      <p className="text-sm font-medium 2xl:text-base">{details}</p>
    </div>
  );
};

const SoftwareDevelopment = () => {
  const texts = [
    "Artificial Intelligence",
    "web 3.0",
    "SOFTWARE DEVELOPMENT",
    "CREATIVE AND MULTIMEDIA DESIGNS",
    "STRATEGIS ADVISORY",
    "FUND RAISING STRATEGY",
    "GAMES DEVELOPMENT",
    "LEAD AMBASSADOR",
  ];

  const logos = [
    "/images/react-logo.svg",
    "/images/js-logo.svg",
    "/images/react-native-logo.svg",
    "/images/python-logo.svg",
    "/images/crystal-logo.svg",
    "/images/bootstrap-logo.svg",
    "/images/next-logo.svg",
    "/images/angular-logo.svg",
    "/images/s-logo.svg",
    "/images/fish-logo.svg",
    "/images/go-logo.svg",
    "/images/dev-ops-logo.svg",
    "/images/js-2-logo.svg",
    "/images/c-logo.svg",
  ];
  const services = [
    {
      image: "/images/soft-1-m.png",
      title: "UX/UI",
      details: "Design engaging and intuitive user interfaces and experiences.",
      isBackground: false,
      count: "01",
    },
    {
      image: "/images/soft-2-m.png",
      title: "Mobile Application Development",
      details: "Build responsive and robust mobile apps for iOS and Android.",
      isBackground: true,
      count: "02",
    },
    {
      image: "/images/soft-3-m.png",
      title: "Prototype Development",
      details:
        "Create detailed prototypes to visualize ideas before full development",
      isBackground: false,
      count: "03",
    },
    {
      image: "/images/soft-4-m.png",
      title: "MVP Development",
      details:
        "Quickly turn concepts into minimal viable products to test market viability.",
      isBackground: true,
      count: "04",
    },
    {
      image: "/images/soft-5-m.png",
      title: "Custom Web Development",
      details:
        "Tailor unique web solutions to meet specific business requirements",
      isBackground: true,
      count: "05",
    },
  ];

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Software Development | Sky Venture Studios</title>
        <link rel="canonical" href="https://www.skyventure.xyz/" />
      </Helmet>
      <section className="w-full bg-ai-bg bg-cover bg-no-repeat h-[600px] 2xl:h-[761px] relative">
        {/* Navbar */}
        <div className="hidden md:absolute lg:block top-[2rem] left-0 right-0">
          <NavBar />
        </div>
        <div className="fixed z-50 lg:hidden top-[0rem] left-0 right-0 bg-white py-4">
          <MobileNav />
        </div>

        {/* Main Content */}
        <div className="flex flex-col items-center justify-end h-full pb-10 text-white">
          <p className="font-extrabold uppercase text-center text-2xl md:text-7xl 2xl:text-app-100 mb-[30px]">
            software <br></br> development
          </p>
          <p className="max-w-4xl px-4 mx-auto text-sm font-normal text-center md:text-2xl 2xl:text-app-32">
            Develop cutting-edge software solutions that drive innovation and
            efficiency, from user experience designs to fully functional custom
            applications
          </p>
        </div>
      </section>
      <Marquee texts={texts} />
      {/* section2 */}
      <div className="py-[50px] md:py-[100px] 2xl:py-[148px] w-full md:w-4/5 px-4 md:px-0 mx-auto">
        <div className="flex justify-center mb-[50px] md:mb-[103px]">
          <AnimatedText
            yPosition={"0"}
            node={
              <>
                <ImageComp
                  image="/images/image 1.svg"
                  styles="hidden md:block"
                />
                <ImageComp
                  image="/images/building-m.svg"
                  styles="block md:hidden"
                />
              </>
            }
          />
        </div>

        <div className="flex flex-wrap items-center justify-center gap-7">
          {logos.map((logo, index) => (
            <div
              key={index}
              style={{ boxShadow: "0px 0px 19.86px 0px #0000001F" }}
              className="rounded-[17px] p-5 md:p-8 2xl:p-12 flex items-center justify-center"
            >
              <ImageComp
                image={logo}
                styles="h-[50px] w-[50px] md:w-[100px] md:h-[100px]"
              />
            </div>
          ))}
        </div>
      </div>
      {/* section3 mobile */}
      <div className="pt-[148px] w-full md:w-4/5 px-4 md:px-0 mx-auto hidden md:block">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 mb-[100px]">
          <ServiceCard1
            title="UX/UI"
            image="bg-bg-soft-1"
            details="Design engaging and intuitive user interfaces and experiences."
            number="01"
          />
          <ServiceCard2
            title="Mobile Application Development"
            image="bg-bg-soft-2"
            details="Build responsive and robust mobile apps for iOS and Android."
            number="02"
          />
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 mb-[100px]">
          <ServiceCard2
            title="Prototype Development"
            image="bg-bg-soft-3"
            details="Create detailed prototypes to visualize ideas before full development"
            number="03"
          />
          <ServiceCard1
            title="MVP Development"
            image="bg-bg-soft-4"
            details="Quickly turn concepts into minimal viable products to test market viability."
            number="04"
          />
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 mb-[100px]">
          <ServiceCard1
            title="Custom Web Development"
            image="bg-bg-soft-5"
            details="Tailor unique web solutions to meet specific business requirements"
            number="05"
          />
        </div>
      </div>
      {/* section3 mobile */}
      <div className="block w-full px-4 mx-auto md:w-4/5 md:px-0 md:hidden">
        {services.map((item, index) => (
          <div
            className={`flex flex-col mb-6 ${
              item?.isBackground
                ? "text-white bg-[#40259E]"
                : "text-black bg-white"
            }`}
            key={index}
          >
            <ImageComp image={item?.image} />
            <div className="flex flex-row gap-6 pt-[27px] pb-[65px]">
              <p className="font-bold text-app-64">{item?.count}</p>
              <div className="flex flex-col">
                <p className="text-sm font-semibold uppercase">{item?.title}</p>
                <p className="text-sm">{item?.details}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* section4 */}
      <div className="py-[50px] md:py-[100px] 2xl:py-[148px] w-full md:w-4/5 px-4 md:px-0 mx-auto">
        <div className="flex flex-col items-center">
          <p className="text-center font-bold text-2xl md:text-5xl lg:text-6xl 2xl:text-app-100 text-app-purple uppercase mb-[50px]">
            Software Development Process
          </p>
          <div className="flex flex-col items-center justify-center gap-6 mb-6 md:gap-0 md:flex-row md:mb-0">
            <ProcessCard
              bg="bg-[#FFF0F0]"
              title="Requirement"
              details="We commence by understanding your specific requirements and
                allocating the necessary resources accordingly."
              count="01"
            />
            <ProcessCard
              bg="bg-[#E9EAFF]"
              title="UX/UI Design"
              details="Our team strives to develop interfaces that harmonize user-friendliness with visual appeal. Subsequently, we will deliver a software prototype for your preliminary assessment and input.."
              count="02"
            />
          </div>
          <div className="flex flex-col items-center justify-center gap-6 md:gap-0 md:flex-row">
            <ProcessCard
              bg="bg-[#FFFBE6]"
              title="Infrastructure & Development"
              details="We establish essential frameworks and systems, then focus on constructing and integrating frontend and backend components for a unified and operational application"
              count="03"
            />
            <ProcessCard
              bg="bg-white"
              title="QA, Apha & Beta Testing"
              details="Ensures software quality, followed by alpha testing to fix initial bugs and beta testing by external users to identify remaining issues before launch"
              count="04"
            />
            <ProcessCard
              bg="bg-[#CFF5FF]"
              title="Roll Out"
              details="Deployment of thoroughly tested software into a live environment for user accessibility and seamless integration."
              count="05"
            />
          </div>
        </div>
      </div>
      <Contact />
      <Footer />
    </>
  );
};

export default SoftwareDevelopment;
