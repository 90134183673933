import React, { useState } from "react";
import NavBar from "../components/navigation/NavBar";
import MobileNav from "../components/navigation/MobileNav";
import Footer from "../components/Footer";
import ImageComp from "../components/Image";
import { Helmet } from "react-helmet";

const CustomCheckbox = ({ label }) => {
  const [checked, setChecked] = useState(false);

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  return (
    <label className="inline-flex items-center cursor-pointer">
      <input
        type="checkbox"
        checked={checked}
        onChange={handleCheckboxChange}
        className="hidden"
      />
      <span
        className={`w-4 h-4 flex items-center justify-center rounded-full border-2 ${checked ? "" : "bg-[#E0E0E0]"
          }`}
      >
        {checked && <ImageComp image="/images/check.svg" />}
      </span>
      <span className="ml-3 font-normal text-black text-app-sm">{label}</span>
    </label>
  );
};

const ContactUs = () => {
  const country = [
    {
      image: "/images/dubai.png",
      country: "Dubai, UAE",
      address: "Office 43-44, Al Fahidi, Bur Dubai, Dubai, UAE",
    },
    {
      image: "/images/paris.png",
      country: "Paris, France",
      address: "66 Avenue des Champs- Elysées 75008",
    },
    {
      image: "/images/edenkoben.png",
      country: "Edenkoben, Germany",
      address: "Nonnenstraße 26 67480 Edenkoben Rheinland-Pfalz",
    },
    {
      image: "/images/ruislip.png",
      country: "Ruislip, England",
      address: "1 Kelvedon Court, Long Drive, Ruislip, England, HA4 0HJ",
    },
  ];

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact | Sky Venture Studios</title>
        <link rel="canonical" href="https://www.skyventure.xyz/" />
      </Helmet>
      <section className="w-full bg-contact-hero-bg bg-cover bg-no-repeat h-[600px] 2xl:h-[761px] relative">
        {/* Navbar */}
        <div className="hidden md:absolute lg:block top-[2rem] left-0 right-0">
          <NavBar />
        </div>
        <div className="fixed z-50 lg:hidden top-[0rem] left-0 right-0 bg-white py-4">
          <MobileNav />
        </div>
        {/* Main Content */}
        <div className="flex flex-col items-center justify-end h-full pb-10 text-white">
          <p className="font-extrabold uppercase text-2xl md:text-7xl 2xl:text-app-100 mb-[120px]">
            Contact Us
          </p>
        </div>
      </section>
      <div className="w-full px-4 md:px-0 md:w-4/5 mx-auto py-8 2xl:py-[69px">
        <p className="text-2xl font-semibold 2xl:text-app-64 mb-11">
          Get in <span className="text-app-purple ">touch</span>
        </p>
        <div className="relative flex flex-col items-center justify-between md:flex-row">
          <div className="w-full md:w-3/5">
            <div className="mb-[70px]">
              <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-[50px] 2xl:gap-[105px] mb-6">
                <div className="flex flex-col gap-2.5 w-full">
                  <label className="font-medium text-app-sm">First Name</label>
                  <input className="border border-[#00000059] rounded-[5px] py-2.5" />
                </div>
                <div className="flex flex-col gap-2.5 w-full">
                  <label className="font-medium text-app-sm">Last Name</label>
                  <input className="border border-[#00000059] rounded-[5px] py-2.5" />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-[50px] 2xl:gap-[105px] mb-6">
                <div className="flex flex-col gap-2.5 w-full">
                  <label className="font-medium text-app-sm">Email</label>
                  <input className="border border-[#00000059] rounded-[5px] py-2.5" />
                </div>
                <div className="flex flex-col gap-2.5 w-full">
                  <label className="font-medium text-app-sm">
                    Phone Number
                  </label>
                  <input className="border border-[#00000059] rounded-[5px] py-2.5" />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-[50px] 2xl:gap-[105px] mb-6">
                <div className="flex flex-col gap-2.5 w-full">
                  <label className="font-medium text-app-sm">
                    Company Name
                  </label>
                  <input className="border border-[#00000059] rounded-[5px] py-2.5" />
                </div>
                <div className="flex flex-col gap-2.5 w-full">
                  <label className="font-medium text-app-sm">
                    Company Website
                  </label>
                  <input className="border border-[#00000059] rounded-[5px] py-2.5" />
                </div>
              </div>
            </div>
            <div className="w-full md:w-4/5 mb-[50px]">
              <p className="mb-4 text-base font-semibold">
                What services are you interested in?
              </p>
              <div className="flex flex-wrap items-center gap-5">
                <CustomCheckbox label="Artificial Intelligence" />
                <CustomCheckbox label="software development" />
                <CustomCheckbox label="Web 3 Development" />
                <CustomCheckbox label="Strategic Advisory" />
                <CustomCheckbox label="Creative Design and Multimedia" />
                <CustomCheckbox label="Fund Raising Strategies" />
                <CustomCheckbox label="Lead Ambassador" />
              </div>
            </div>

            <div className="w-full md:w-4/5 mb-[50px] 2xl:mb-[121px]">
              <div className="flex flex-col gap-2.5 w-full mb-6">
                <label className="font-medium text-app-sm">Message</label>
                <textarea
                  className="resize-none border border-[#00000059] rounded-[5px] p-3 h-[128px]"
                  placeholder="Write your message.."
                />
              </div>
              <div className="w-full md:w-4/5">
                <button className="text-white bg-app-purple py-4 cursor-default rounded-[10px] text-sm w-full">
                  Submit
                </button>
              </div>
            </div>
          </div>
          <div className="flex justify-end h-full">
            <div className="flex flex-row md:flex-col items-center justify-end gap-0 md:gap-8 mb-[50px] md:mb-0">
              <img
                style={{ boxShadow: "0px 14px 59px 7px #6F00FF17" }}
                src="/images/contact-medium.svg"
                alt="icon"
                className="max-w-16 max-h-16 2xl:w-[96px] 2xl:height-[96px] rounded-[30px] object-cover"
              />
              <ImageComp
                image="/images/divider.svg"
                styles="w-[96px] 2xl:w-[148px] h-3 rotate-90 md:rotate-0"
              />
              <img
                style={{ boxShadow: "0px 14px 59px 7px #6F00FF17" }}
                src="/images/contact-linkedin.svg"
                alt="icon"
                className="max-w-16 max-h-16 2xl:w-[96px] 2xl:height-[96px] rounded-[30px] object-cover"
              />
              <ImageComp
                image="/images/divider.svg"
                styles="w-[96px] 2xl:w-[148px] h-3 rotate-90 md:rotate-0"
              />
              <img
                style={{ boxShadow: "0px 14px 59px 7px #6F00FF17" }}
                src="/images/contact-twitter.svg"
                alt="icon"
                className="max-w-16 max-h-16 2xl:w-[96px] 2xl:height-[96px] rounded-[30px] object-cover"
              />
            </div>
          </div>
        </div>

        {/* <div className="grid grid-cols-2 gap-6">
          {country?.map((item, index) => (
            <div
              className="flex flex-col items-start gap-4 md:flex-row md:items-center"
              key={index}
            >
              <ImageComp image={item?.image} width={60} height={60} />
              <div className="flex flex-col">
                <p className="text-sm font-medium text-black md:text-base 2xl:text-2xl">
                  {item?.country}
                </p>
                <p className="text-sm text-black md:text-base 2xl:text-2xl">
                  {item?.address}
                </p>
              </div>
            </div>
          ))}
        </div> */}
      </div>
      <Footer />
    </>
  );
};

export default ContactUs;
