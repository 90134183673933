import React from "react";
import NavBar from "../../components/navigation/NavBar";
import MobileNav from "../../components/navigation/MobileNav";
import ImageComp from "../../components/Image";
import ServiceCard1 from "../../components/ServiceCard1";
import ServiceCard2 from "../../components/ServiceCard2";
import Contact from "../../components/Contact";
import Footer from "../../components/Footer";
import Marquee from "../../components/Marque";
import { Helmet } from "react-helmet";
import AnimatedText from "../../components/AnimatedText";

const ArtificialIntelligence = () => {
  const texts = [
    "AI VOICE ASSISTANT",
    "AI DIALER",
    "AI CHAT ASSISTANT AND CHAT BOT",
    "AI RESUME BUILDER",
    "ai automation",
    "ai date extraction engine",
    "ai date extraction engine",
    "ai date extraction engine",
  ];
  const benefits = [
    {
      image: "/images/clock.svg",
      title: "Accelerate Time to Market",
      details:
        "We speed up time to market by clear specifications, foreseen technical challenges, strong team management, and improvement",
    },
    {
      image: "/images/adaptive.svg",
      title: "Adaptive & Strategic Approaches",
      details:
        "Agile Development and flexible Frameworks are used to continuously adapt to market demand, develop precisely based on clients’ requirements",
    },
    {
      image: "/images/customer.svg",
      title: "Customer Satisfaction",
      details: "We design and develop AI Solutions that solve your challenges, practical quality assurance on real devices to ensure the best performance",
    },
  ];

  const services = [
    {
      image: "/images/ai-1-m.png",
      title: "AI Voice Assistant",
      details:
        "Improve customer interaction with voice-activated AI technology",
      isBackground: false,
      count: "01",
    },
    {
      image: "/images/ai-2-m.png",
      title: "AI Dialer",
      details:
        "Enhance call operations with AI that automates and optimizes communication.",
      isBackground: true,
      count: "02",
    },
    {
      image: "/images/ai-3-m.png",
      title: "AI Chat Assistant & Chat Bot",
      details: "Engage users 24/7 with smart, responsive AI chat bots.",
      isBackground: false,
      count: "03",
    },
    {
      image: "/images/ai-4-m.png",
      title: "AI Resume Builder",
      details:
        "Explore the limitless possibilities of AI  with Sky Venture Studios",
      isBackground: true,
      count: "04",
    },
    {
      image: "/images/ai-5-m.png",
      title: "AI Automation",
      details:
        "Streamline business processes with tailored AI automation solutions.",
      isBackground: false,
      count: "05",
    },
    {
      image: "/images/ai-6-m.png",
      title: "AI Data Extraction Engine",
      details: "Extract and process data efficiently using AI.",
      isBackground: true,
      count: "06",
    },
    {
      image: "/images/ai-7-m.png",
      title: "AI Predictor",
      details: "Forecast outcomes accurately using AI predictive analytics.",
      isBackground: false,
      count: "07",
    },
    {
      image: "/images/ai-8-m.png",
      title: "Custom AI Agent",
      details: "Create bespoke AI solutions tailored to your specific needs.",
      isBackground: true,
      count: "08",
    },
  ];
  const aiDeciphiningCards = [
    {
      image: "/images/machine-learning.svg",
      imageVariant: "/images/machine-learning-white.svg",
      text: "Machine Learning Mastery",
      details:
        "Specializing in developing intelligent algorithms for predictive analytics, pattern recognition, and decision-making.",
    },
    {
      image: "/images/natrural-learning.svg",
      imageVariant: "/images/natrural-learning-white.svg",
      text: "Natural Language Processing",
      details:
        "From sentiment analysis to language translation, our NLP expertise interprets, and responds to human language nuances.",
    },
    {
      image: "/images/neural-network.svg",
      imageVariant: "/images/neural-network-white.svg",
      text: "Neural Networks Expertise",
      details:
        "With neural architectures, we enable machines to mimic the human brain's interconnected structure.",
    },
    {
      image: "/images/ethical-intelligence.svg",
      imageVariant: "/images/ethical-intelligence-white.svg",
      text: "Ethical Intelligence",
      details:
        "We know it is important to treat AI as a tool rather than an all-knowing oracle and continuously question and analyse results.",
    },
  ];
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Artificial Intelligence | Sky Venture Studios</title>
        <link rel="canonical" href="https://www.skyventure.xyz/" />
      </Helmet>
      <section className="w-full bg-ai-bg bg-cover bg-no-repeat h-[600px] 2xl:h-[761px] relative">
        {/* Navbar */}
        <div className="hidden md:absolute lg:block top-[2rem] left-0 right-0">
          <NavBar />
        </div>
        <div className="fixed z-50 lg:hidden top-[0rem] left-0 right-0 bg-white py-4">
          <MobileNav />
        </div>

        {/* Main Content */}
        <div className="flex flex-col items-center justify-end h-full pb-10 text-white">
          <p className="font-extrabold uppercase text-center text-2xl md:text-7xl 2xl:text-app-100 mb-[30px]">
            ARTIFICIAL <br></br> INTELLIGENCE
          </p>
          <p className="max-w-4xl px-4 mx-auto text-sm font-normal text-center md:text-2xl 2xl:text-app-32">
            Harness the power of AI to automate processes, enhance
            decision-making, and transform your business operations with our
            advanced artificial intelligence solutions.
          </p>
        </div>
      </section>
      <Marquee texts={texts} />
      {/* section2 */}
      <div className="md:bg-ai-section-2-bg bg-contain 2xl:bg-cover bg-no-repeat px-4 md:px-0 mb-[100px]">
        <div className="w-full md:w-4/5 mx-auto pt-[114px]">
          <div className="flex items-center justify-center h-full">
            <div>
              <div className="flex flex-col items-center justify-center mb-[50px] md:mb-[129px]">

                <AnimatedText yPosition={'0'} node={(
                  <>
                    <div className="flex relative md:my-6">
                      <ImageComp
                        image="/images/decipher.svg"
                        styles="object-cover hidden md:block z-40"
                      />
                      <div className="flex absolute right-[50%] translate-x-1/2 overflow-visible">
                        <ImageComp
                          image="/images/TECHNOLOGOES.svg"
                          className="object-cover hidden md:block  w-full h-20"
                        />
                      </div>
                    </div>
                    <ImageComp
                      image="/images/decipher-m.svg"
                      styles="block md:hidden"
                    />
                  </>
                )} />
                <p className="text-sm font-medium md:mt-4 text-center md:text-xl">
                  Octaloop Technologies, the vanguard of innovation, is ushering
                  in a transformative era in the technological landscape.  With
                  a specialization in Artificial Intelligence, we utilize
                  machine learning, neural networks, and advanced algorithms to
                  develop intelligent solutions that redefine how businesses
                  operate.
                </p>
              </div>
              {/* <div className="grid grid-cols-0 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-[50px] justify-center"> */}
              <div className="flex flex-row flex-wrap gap-[50px] justify-center">
                {aiDeciphiningCards.map((item, index) => (
                  <div
                    key={index}
                    className="px-[30px] relative group rounded-[17px]  cursor-pointer overflow-hidden pt-[57px] pb-[42px] flex flex-col items-center bg-white max-w-[286px]"
                    style={{ boxShadow: "0px 0px 19px 0px #00000017" }}
                  >
                    <div className="absolute -bottom-[14rem] left-[50%] -translate-x-[50%] transition-all group-hover:h-[50rem] group-hover:w-[50rem] duration-700  rounded-full h-[15.635rem] w-[15.635rem] bg-app-purple"></div>
                    <div className="relative">
                      <ImageComp image={item?.image} styles="mb-4" />
                      <div className="transition-all absolute top-0 right-0 opacity-0 z-40 group-hover:opacity-100 duration-700">
                        <ImageComp image={item?.imageVariant} styles="mb-4" />
                      </div>
                    </div>
                    <p className="text-base md:text-xl font-semibold transition-colors group-hover:text-white z-40 duration-700 text-center pb-[30px]">
                      {item?.text}
                    </p>
                    <p className="text-center transition-colors group-hover:text-white z-40 duration-700 text-[#717171] text-base">
                      {item?.details}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* section3 */}
      <div className="pt-[148px] w-full md:w-4/5 px-4 md:px-0 mx-auto hidden md:block">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 mb-[100px]">
          <ServiceCard1
            title="AI Voice Assistant"
            image="bg-bg-ai-1"
            details="Improve customer interaction with voice-activated AI technology"
            number="01"
          />
          <ServiceCard2
            title="AI Dialer"
            image="bg-bg-ai-2"
            details="Enhance call operations with AI that automates and optimizes communication."
            number="02"
          />
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 mb-[100px]">
          <ServiceCard2
            title="AI Chat Assistant & Chat Bot"
            image="bg-bg-ai-3"
            details="Engage users 24/7 with smart, responsive AI chatbots."
            number="03"
          />
          <ServiceCard1
            title="AI Resume Builder"
            image="bg-bg-ai-4"
            details="Explore the limitless possibilities of AI with Sky Venture Studios"
            number="04"
          />
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 mb-[100px]">
          <ServiceCard1
            title="AI Automation"
            image="bg-bg-ai-5"
            details="Streamline business processes with tailored AI automation solutions."
            number="05"
          />
          <ServiceCard2
            title="AI Data Extraction Engine"
            image="bg-bg-ai-6"
            details="Extract and process data efficiently using AI."
            number="06"
          />
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 mb-[100px]">
          <ServiceCard2
            title="AI Predictor"
            image="bg-bg-ai-7"
            details="Forecast outcomes accurately using AI predictive analytics."
            number="07"
          />
          <ServiceCard1
            title="Custom AI Agent"
            image="group-hover:bg-bg-ai-8"
            details="Create bespoke AI solutions tailored to your specific needs."
            number="08"
          />
        </div>
      </div>
      {/* section3 mobile */}
      <div className="block w-full px-4 mx-auto md:w-4/5 md:px-0 md:hidden">
        {services.map((item, index) => (
          <div
            className={`flex flex-col mb-6 ${item?.isBackground
              ? "text-white bg-[#40259E]"
              : "text-black bg-white"
              }`}
            key={index}
          >
            <ImageComp image={item?.image} />
            <div className="flex flex-row gap-6 pt-[27px] pb-[65px]">
              <p className="font-bold text-app-64">{item?.count}</p>
              <div className="flex flex-col">
                <p className="text-sm font-semibold">{item?.title}</p>
                <p className="text-sm">{item?.details}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* section4 */}
      <div className="w-full md:w-4/5 px-4 md:px-0 mx-auto py-[90px]">
        <p className="text-left md:text-center font-bold text-2xl md:text-5xl lg:text-7xl 2xl:text-app-100 text-app-purple uppercase mb-[50px] 2xl:mb-[100px]">
          Benefits of Choosing Us
        </p>
        <div className="grid justify-center gap-10 grid-cols-0 md:grid-cols-2 lg:grid-cols-3">
          {benefits.map((item, index) => (
            <div
              className="rounded-[10px] p-8 max-w-[465px]"
              style={{ boxShadow: "12px 12px 20px 2px #9194FC0D" }}
              key={index}
            >
              <ImageComp image={item?.image} styles="mb-12" />
              <div className="flex flex-col gap-3">
                <p className="text-base font-semibold lg:text-2xl 2xl:text-app-3xl-2 text-app-purple">
                  {item?.title}
                </p>
                <p className="text-sm font-medium 2xl:text-base">
                  {item?.details}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Contact />
      <Footer />
    </>
  );
};

export default ArtificialIntelligence;
