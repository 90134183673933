import React from "react";
import NavBar from "../../components/navigation/NavBar";
import MobileNav from "../../components/navigation/MobileNav";
import ImageComp from "../../components/Image";
import ServiceCard2 from "../../components/ServiceCard2";
import ServiceCard1 from "../../components/ServiceCard1";
import Marquee from "../../components/Marque";
import { Helmet } from "react-helmet";
import Contact from "../../components/Contact";
import Footer from "../../components/Footer";

const CreativeDesign = () => {
  const texts = [
    "Artificial Intelligence",
    "web 3.0",
    "SOFTWARE DEVELOPMENT",
    "CREATIVE AND MULTIMEDIA DESIGNS",
    "STRATEGIS ADVISORY",
    "FUND RAISING STRATEGY",
    "GAMES DEVELOPMENT",
    "LEAD AMBASSADOR",
  ];

  const services = [
    {
      image: "/images/create-1-m.png",
      title: "Logo Designing",
      details:
        "Create memorable and distinctive logos that represent your brand.",
      isBackground: false,
      count: "01",
    },
    {
      image: "/images/create-2-m.png",
      title: "2D & 3D Designing",
      details:
        "Craft vivid and detailed graphic designs in two and three dimensions.",
      isBackground: true,
      count: "02",
    },
    {
      image: "/images/create-3-m.png",
      title: "Animation",
      details: "Develop animations that tell stories and engage audiences.",
      isBackground: false,
      count: "03",
    },
    {
      image: "/images/create-4-m.png",
      title: "Cinematic Trailer",
      details: "Produce cinematic-quality trailers to market and promote",
      isBackground: true,
      count: "04",
    },
  ];

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Creative Design | Sky Venture Studios</title>
        <link rel="canonical" href="https://www.skyventure.xyz/" />
      </Helmet>
      <section className="w-full bg-ai-bg bg-cover bg-no-repeat h-[600px] 2xl:h-[761px] relative">
        {/* Navbar */}
        <div className="hidden md:absolute lg:block top-[2rem] left-0 right-0">
          <NavBar />
        </div>
        <div className="fixed z-50 lg:hidden top-[0rem] left-0 right-0 bg-white py-4">
          <MobileNav />
        </div>

        {/* Main Content */}
        <div className="flex flex-col items-center justify-end h-full pb-10 text-white">
          <p className="font-extrabold uppercase text-center text-2xl md:text-7xl 2xl:text-app-100 mb-[30px]">
            Creative Design <br></br> & Multimedia
          </p>
          <p className="max-w-4xl px-4 mx-auto text-sm font-normal text-center md:text-2xl 2xl:text-app-32">
            A From dynamic 2D and 3D designs to impactful branding, explore our
            comprehensive range of creative design and multimedia services
          </p>
        </div>
      </section>
      <Marquee texts={texts} />
      {/* section2 mobile */}
      <div className="pt-[148px] w-full md:w-4/5 px-4 md:px-0 mx-auto hidden md:block">
        <div className="grid grid-cols-1 gap-2 lg:grid-cols-2 mb-[100px]">
          <ServiceCard1
            title="Logo Designing"
            image="bg-bg-create-1"
            details="Create memorable and distinctive logos that represent your brand."
            number="01"
          />
          <ServiceCard2
            title="2D & 3D Designing"
            image="bg-bg-create-2"
            details="Craft vivid and detailed graphic designs in two and three dimensions."
            number="02"
          />
        </div>
        <div className="grid grid-cols-1 gap-2 lg:grid-cols-2 mb-[100px]">
          <ServiceCard2
            title="Animation"
            image="bg-bg-create-3"
            details="Develop animations that tell stories and engage audiences."
            number="03"
          />
          <ServiceCard1
            title="Cinematic Trailer"
            image="bg-bg-create-4"
            details="Produce cinematic-quality trailers to market and promote"
            number="04"
          />
        </div>
      </div>
      {/* section2 mobile */}
      <div className="block w-full px-4 mx-auto md:w-4/5 md:px-0 md:hidden">
        {services.map((item, index) => (
          <div
            className={`flex flex-col mb-6 ${
              item?.isBackground
                ? "text-white bg-[#40259E]"
                : "text-black bg-white"
            }`}
            key={index}
          >
            <ImageComp image={item?.image} />
            <div className="flex flex-row gap-6 pt-[27px] pb-[65px]">
              <p className="font-bold text-app-64">{item?.count}</p>
              <div className="flex flex-col">
                <p className="text-sm font-semibold uppercase">{item?.title}</p>
                <p className="text-sm">{item?.details}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Contact />
      <Footer />
    </>
  );
};

export default CreativeDesign;
