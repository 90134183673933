import React from "react";
import ImageComp from "./Image";
import { Link } from "react-router-dom";

const Footer = () => {
  const footerCountry = [
    {
      image: "/images/dubai.png",
      country: "Dubai, UAE",
      address: "Office 43-44, Al Fahidi, Bur Dubai, Dubai, UAE",
    },
    {
      image: "/images/paris.png",
      country: "Paris, France",
      address: "66 Avenue des Champs- Elysées 75008",
    },
    {
      image: "/images/edenkoben.png",
      country: "Edenkoben, Germany",
      address: "Nonnenstraße 26 67480 Edenkoben Rheinland-Pfalz",
    },
    {
      image: "/images/ruislip.png",
      country: "Ruislip, England",
      address: "1 Kelvedon Court, Long Drive, Ruislip, England, HA4 0HJ",
    },
  ];

  const socials = [
    { image: "/images/medium.png", text: "Medium" },
    { image: "/images/twitter.png", text: "Twitter" },
    { image: "/images/linkedin.png", text: "Linkedin" },
  ];

  return (
    <div className="bg-black px-6 md:px-10 py-[100px] 2xl:p-[141px]">
      <div className="relative flex flex-col gap-16 md:flex-row 2xl:gap-28">
        <div className="flex flex-col w-full gap-8 text-justify md:w-3/5">
          <div className="flex items-center gap-3.5">
            {/*<ImageComp image="/images/footer-logo.svg" />*/}
            <ImageComp image="/images/sky-venture-studios.svg" />
            {/* <p className="text-xl font-normal text-white uppercase md:text-2xl 2xl:text-app-36">
              SKY VENTURE STUDIO
            </p> */}
          </div>
          <p className="text-sm text-white/75 md:text-app-15">
            Sky Venture Studios revolutionizes startup financing with our
            'Innovate Now, Pay Later' program, enabling businesses to launch
            projects with minimal initial investment and spread costs. Committed
            to sustainable development and technological advancement, we drive
            innovation across industries globally. Join our movement now as we
            pave the way towards a more innovative future.
          </p>
          {/* <div className="flex flex-col gap-6">
            {footerCountry?.map((item, index) => (
              <div className="flex items-center gap-4" key={index}>
                <ImageComp image={item?.image} width={28} height={38} />
                <div className="flex flex-col">
                  <p className="text-xs text-[#C1C1C1] font-medium">
                    {item?.country}
                  </p>
                  <p className="text-xs text-[#C1C1C1]">{item?.address}</p>
                </div>
              </div>
            ))}
          </div> */}
        </div>
        {/* <div className="flex flex-row py-6 lg:py-0 lg:flex-col items-center md:items-start justify-center gap-10 border-y-2 lg:border-y-0 lg:border-x-2 border-white h-full col-span-1 px-[50px]">
          {socials.map((item, index) => (
            <div className="flex items-center gap-2.5">
              <ImageComp
                image={item?.image}
                styles="h-6 md:h-[36px] w-6 md:w-[38px]"
              />
              <p className="text-sm text-white md:text-lg 2xl:text-xl">
                {item.text}
              </p>
            </div>
          ))}
        </div> */}
        <div className="flex flex-col items-center justify-between w-full text-center md:text-left md:w-2/5 ">
          <div className="flex flex-col items-center md:items-start">
            <p className="col-span-2 mb-6 font-extrabold text-white uppercase text-app-32 md:text-6xl 2xl:text-app-80 font-roboto">
              get a <span className="text-app-purple">quote</span>
            </p>
            <p className="font-roboto text-[#E1E1E1] font-light w-2/3 md:-w-full text-sm md:text-base 2xl:text-app-22 mb-6">
              Book your free consultation with our expert today.
            </p>
            <button className="px-4 md:px-8 2xl:px-16 py-[13px] md:py-4 2xl:py-6 text-white bg-app-purple font-normal md:font-semibold text-base 2xl:text-app-25 rounded-[42px]">
              <Link to="/contact">Get a Quote</Link>
            </button>
          </div>
          <div className="flex items-center gap-8 2xl:gap-[100px] mt-[50px] md:mt-0">
            <p className="text-app-15 text-[#C1C1C1]">
              <Link to="/terms-and-conditions">Terms & Conditions</Link>
            </p>
            <p className="text-app-15 text-[#C1C1C1]">
              <Link to="/privacy-and-policy">Privacy Policy</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
