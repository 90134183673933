import React from "react";
import NavBar from "../../components/navigation/NavBar";
import MobileNav from "../../components/navigation/MobileNav";
import ImageComp from "../../components/Image";
import ServiceCard1 from "../../components/ServiceCard1";
import ServiceCard2 from "../../components/ServiceCard2";
import Contact from "../../components/Contact";
import Footer from "../../components/Footer";
import Marquee from "../../components/Marque";
import { Helmet } from "react-helmet";

const WebDevelopment = () => {
  const texts = [
    "Artificial Intelligence",
    "web 3.0",
    "SOFTWARE DEVELOPMENT",
    "CREATIVE AND MULTIMEDIA DESIGNS",
    "STRATEGIES ADVISORY",
    "FUND RAISING STRATEGY",
    "GAMES DEVELOPMENT",
    "LEAD AMBASSADOR",
  ];
  const services = [
    {
      image: "/images/web-1-m.png",
      title: "DEFI",
      details:
        "Create decentralized finance applications to revolutionize financial interactions.",
      isBackground: false,
      count: "01",
    },
    {
      image: "/images/web-2-m.png",
      title: "Smart Contracts",
      details: "Develop and deploy self-executing contracts with blockchain.",
      isBackground: true,
      count: "02",
    },
    {
      image: "/images/web-3-m.png",
      title: "DApp Development",
      details:
        "Build decentralized applications that run on a peer-to-peer network",
      isBackground: false,
      count: "03",
    },
    {
      image: "/images/web-4-m.png",
      title: "NFT Marketplace",
      details:
        "Design platforms for creating, selling, and trading digital assets.",
      isBackground: true,
      count: "04",
    },
    {
      image: "/images/web-5-m.png",
      title: "Blockchain Layers",
      details:
        "Implement multi-layer blockchain architectures for scalability and efficiency.",
      isBackground: false,
      count: "05",
    },
  ];

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Web Development | Sky Venture Studios</title>
        <link rel="canonical" href="https://www.skyventure.xyz/" />
      </Helmet>
      <section className="w-full bg-ai-bg bg-cover bg-no-repeat h-[600px] 2xl:h-[761px] relative">
        {/* Navbar */}
        <div className="hidden md:absolute lg:block top-[2rem] left-0 right-0">
          <NavBar />
        </div>
        <div className="fixed z-50 lg:hidden top-[0rem] left-0 right-0 bg-white py-4">
          <MobileNav />
        </div>

        {/* Main Content */}
        <div className="flex flex-col items-center justify-end h-full pb-10 text-white">
          <p className="font-extrabold uppercase text-center text-2xl md:text-7xl 2xl:text-app-100 mb-[30px]">
            WEB 3<br></br> DEVELOPMENT
          </p>
          <p className="max-w-4xl px-4 mx-auto text-sm font-normal text-center md:text-2xl 2xl:text-app-32">
            Leverage the latest in blockchain technology to develop
            decentralized applications and systems that enhance security and
            transparency
          </p>
        </div>
      </section>
      <Marquee texts={texts} />
      {/* section2 */}
      <div className="w-full px-4 py-16 mx-auto md:w-4/5 md:px-0">
        <div className="grid grid-cols-0 md:grid-cols-2 gap-[30px] justify-center">
          <div className="order-2 md:order-1">
            <p className="font-bold text-2xl md:text-5xl lg:text-6xl 2xl:text-app-100 text-app-purple uppercase mb-10 lg:mb-[50px] 2xl:mb-[100px]">
              The Blockchain Fever
            </p>
            <p className="mb-8 font-medium text-app-sm 2xl:text-xl">
              Sky Venture Studio, the vanguard of innovation, is ushering in a
              transformative era in the technological landscape.  With a
              specialization in Artificial Intelligence, we utilize machine
              learning, neural networks, and advanced algorithms to develop
              intelligent solutions that redefine how businesses operate.
            </p>
            <div className="flex items-center gap-2.5 bg bg-gradient-to-r from-[#6F00FF] to-[#6F00FF00] px-3 py-3.5 rounded-l-[10px] w-full lg:w-4/5 text-white mb-[50px]">
              <ImageComp image="/images/infinity.svg" />
              <p className="text-sm font-bold 2xl:text-2xl">
                Decoding the Cryptic World
              </p>
            </div>
            <div className="flex flex-col gap-4 md:gap-6 2xl:gap-[45px]">
              <p className="text-sm md:text-base 2xl:text-2xl">
                Blockchain Inventory
              </p>
              <p className="text-sm md:text-base 2xl:text-2xl">
                Decentralized Lounges
              </p>
              <p className="text-sm md:text-base 2xl:text-2xl">
                Notoriously Fun Treasures
              </p>
            </div>
          </div>

          <ImageComp
            image="/images/web-development-1.png"
            styles="order-1 md:order-2"
          />
        </div>
      </div>
      <div className="pt-[148px] w-full md:w-4/5 px-4 md:px-0 mx-auto hidden md:block">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 mb-[100px]">
          <ServiceCard1
            title="Defi"
            image="bg-bg-web-1"
            details="Create decentralized finance applications to revolutionize financial interactions."
            number="01"
          />
          <ServiceCard2
            title="Smart Contracts"
            image="bg-bg-web-2"
            details="Develop and deploy self-executing contracts with blockchain."
            number="02"
          />
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 mb-[100px]">
          <ServiceCard2
            title="DApp Development"
            image="bg-bg-web-3"
            details="Build decentralized applications that run on a peer-to-peer network"
            number="03"
          />
          <ServiceCard1
            title="NFT Marketplace"
            image="bg-bg-web-4"
            details="Design platforms for creating, selling, and trading digital assets."
            number="04"
          />
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 mb-[100px]">
          <ServiceCard1
            title="Blockchain Layers"
            image="bg-bg-web-5"
            details="Implement multi-layer blockchain architectures for scalability and efficiency."
            number="05"
          />
        </div>
      </div>
      {/* section3 mobile */}
      <div className="block w-full px-4 mx-auto md:w-4/5 md:px-0 md:hidden">
        {services.map((item, index) => (
          <div
            className={`flex flex-col mb-6 ${item?.isBackground
              ? "text-white bg-[#40259E]"
              : "text-black bg-white"
              }`}
            key={index}
          >
            <ImageComp image={item?.image} />
            <div className="flex flex-row gap-6 pt-[27px] pb-[65px]">
              <p className="font-bold text-app-64">{item?.count}</p>
              <div className="flex flex-col">
                <p className="text-sm font-semibold uppercase">
                  {item?.title}
                </p>
                <p className="text-sm">{item?.details}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Contact />
      <Footer />
    </>
  );
};

export default WebDevelopment;
