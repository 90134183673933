import React from "react";
import ImageComp from "./Image";

const ServiceCard2 = ({ title, number, details, image }) => {
  return (
    <div className="text-white group">
      <div
        className={`px-[50px] w-[2/5] pt-[149px] bg-[#40259E] h-96 relative`}
      >
        <div className={`flex absolute top-0 ${image} transition-all opacity-0 ease-in-out group-hover:opacity-100 duration-500 right-0 h-full w-full`}></div>

        <p className="pb-4 text-2xl font-bold uppercase">
          {title}
        </p>
        <p className="text-xl">
          {details}
        </p>
      </div>
      <div className="flex justify-between py-1.5 items-center bg-[#40259E]">
        <div className="flex items-center gap-8">
          <p className="font-bold text-app-100-n 2xl:text-app-183-n">{number}</p>
          <p className="pb-4 text-2xl font-bold uppercase transition-all duration-300 ease-in-out opacity-0 group-hover:opacity-100">
            {title}
          </p>
        </div>

        <div className="flex items-center justify-center p-5 transition-all duration-300 ease-in-out border border-white rounded-full cursor-pointer hover:rounded-lg">
          <ImageComp image="/images/up-arrow-white.svg" />
        </div>
      </div>
    </div>
  );
};

export default ServiceCard2;
